import { FC, useEffect, useRef, useState } from 'react'
import { Box, BoxProps, Hidden, IconType, Text } from 'ui/core'
import { HeaderButton } from '../headerControls/headerControls'
import { CounterWrapper } from './headerControls.styles'

export const HeaderListButtons: FC<{
  basketCount: string
  wishlistCount: string
  userName: string
  isLoggedIn: boolean
  toggleUserMenu: () => void
}> = ({ basketCount, wishlistCount, userName, isLoggedIn, toggleUserMenu }) => {
  return (
    <Box
      display="flex"
      justifyContent={['space-around', 'flex-end', 'space-between']}
      width="full"
      px={[1, 2, 0]}
    >
      <Hidden above="md">
        {userName && isLoggedIn ? (
          <Box
            role="button"
            onClick={toggleUserMenu}
            display="flex"
            height="full"
            alignItems="center"
            pr={1}
          >
            <HeaderButton
              iconLeft="user"
              iconColor="dark300"
              hiddenBelow={isLoggedIn && userName ? 'xl' : 'lg'}
            >
              {userName || 'Innskráning'}
            </HeaderButton>
          </Box>
        ) : (
          <Box pr={1} display="flex" height="full" alignItems="center">
            <HeaderButton
              href="https://vidskiptavefur.husa.is/enteriskr"
              iconLeft="user"
              iconColor="dark300"
              hiddenBelow={isLoggedIn && userName ? 'xl' : 'lg'}
            >
              Innskráning
            </HeaderButton>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="full"
          mx={[0, 1, 2]}
        >
          <HeaderButton
            href={'/upplysingar/afgreidslutimi/'}
            iconLeft="time"
            iconColor="dark300"
            hiddenBelow={isLoggedIn && userName ? 'xl' : 'lg'}
          >
            Afgreiðslutímar
          </HeaderButton>
        </Box>
      </Hidden>
      <Hidden below="md">
        <HeaderListButton
          icon="heart"
          initialCount={wishlistCount}
          borderLeft={['none', 'none', 'light']}
          className="wishlist-trigger"
          countClass="wishlist_counter"
        />
      </Hidden>
      <HeaderListButton
        icon="cart"
        initialCount={basketCount}
        borderLeft={['none', 'none', 'light']}
        className="basket-trigger"
        countClass="basket_counter"
      />
    </Box>
  )
}

interface HeaderListButtonProps {
  icon: IconType
  countClass: string
  initialCount: string
}

const HeaderListButton: FC<HeaderListButtonProps & BoxProps> = ({
  icon,
  className,
  countClass,
  initialCount,

  ...boxProps
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [count, setCount] = useState<string>(initialCount)

  // Observe dom changes to cart/wishlist count change
  // When count change fires in umbraco project
  var observer = new MutationObserver(() => {
    if (ref.current) setCount(ref.current.innerText)
  })
  useEffect(() => {
    if (ref.current) observer.observe(ref.current, { childList: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
  const basketEmpty = !(count && count !== '0')
  return (
    <Box
      {...boxProps}
      display="flex"
      justifyContent="center"
      position="relative"
      alignItems="center"
      height="full"
      pr={[1, 2, 3]}
      pl={[1, 2, 3]}
      py={[2, 2, 0]}
    >
      <HeaderButton iconLeft={icon} iconColor="dark300" className={className} />
      <CounterWrapper
        position="absolute"
        opacity={basketEmpty ? 0 : 1}
        height="3/10"
        borderRadius="circle"
        bg="primary300"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={className || ''}
      >
        <Text p={0} m={0} color="white" variant="textSmall">
          <Box ref={ref} as="span" className={countClass}>
            {count}
          </Box>
        </Text>
      </CounterWrapper>
    </Box>
  )
}
