import { FC } from 'react'
import { MenuNode, ThemeVariant } from 'types'
import {
  Box,
  Text,
  Icon,
  Anchor,
  ALL_FIGURES,
  Figure,
  FigureType,
  ALL_ICONS,
  IconType,
} from 'ui/core'
import { ThemeColorProp, ThemeZIndexProp } from 'ui/theme'
import { FadeInContent, MenuExpandableAnchor } from '../menu.styles'
import { MobileHeaderContainer } from './mobileMenu.styles'

interface MobileMenuTopProps {
  parentCategory?: string
  parentUrl?: string
  itemActive?: string | null
  goBack?: () => void
  contentTop?: JSX.Element
}

interface MenuScreenProps extends MobileMenuTopProps {
  items: MenuNode[]
  itemActive?: string | null
  onClick?: (item: MenuNode) => void
  zIndex?: ThemeZIndexProp
}

// A screen of menu for a given category in mobile
export const MobileMenuScreen: FC<
  MenuScreenProps & MobileMenuTopProps & { theme?: ThemeVariant }
> = ({
  contentTop,
  parentCategory,
  parentUrl,
  goBack,
  items,
  theme,
  ...menuScreenProps
}) => (
  <Box
    height="full"
    width="full"
    display="flex"
    flexDirection="column"
    justifyContent="flex-start"
    alignItems="stretch"
    overflow="scroll"
  >
    <FadeInContent width="full" height="full">
      <MobileMenuTop
        contentTop={contentTop}
        parentCategory={parentCategory}
        parentUrl={parentUrl}
        goBack={goBack}
      />
      <Box>
        {items.map((item, index) => (
          <MenuScreen key={index} item={item} {...menuScreenProps} />
        ))}
      </Box>
    </FadeInContent>
  </Box>
)

// Top controls for said menu, either custom controls
// or option to go back to parent category menu screen
export const MobileMenuTop: FC<MobileMenuTopProps> = ({
  contentTop,
  parentCategory,
  parentUrl,
  goBack,
}) => (
  <MobileHeaderContainer
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    width="full"
    borderTop="darkGray"
    borderBottom="darkGray"
    bg="background"
  >
    {contentTop ? (
      <Box width="full" display="flex">
        {contentTop}
      </Box>
    ) : (
      <Box
        width="full"
        display="flex"
        justifyContent={goBack ? 'space-between' : 'flex-end'}
        py={2}
        px={4}
      >
        {goBack && (
          <Anchor>
            <Box
              onClick={goBack}
              height="full"
              display="flex"
              alignItems="center"
            >
              <Icon type="arrowLeft" size="18" color="primary300" />
            </Box>
          </Anchor>
        )}
        <Anchor href={parentUrl || '#'}>
          <Text mb={0} variant="h5" fontWeight="medium" color="dark500">
            {parentCategory || ''}
          </Text>
        </Anchor>
      </Box>
    )}
  </MobileHeaderContainer>
)

// Links within menu/category
export const MenuScreen: FC<{
  item: MenuNode
  itemActive?: string | null
  onClick?: (item: MenuNode) => void
  iconSize?: string | number
  color?: ThemeColorProp
}> = ({ item, itemActive, onClick, iconSize, color }) => (
  <Box width="full" justifyContent="space-between" py={2} pl={3}>
    <MenuExpandableAnchor
      onClick={() =>
        item.Children && item.Children.length > 0 && onClick
          ? onClick(item)
          : null
      }
      href={!item.Children || item.Children.length < 1 ? item?.Url : undefined}
    >
      <Box width="full" display="flex">
        {item?.Icon && !ALL_FIGURES.includes(item?.Icon as FigureType) ? (
          <Icon
            type={
              ALL_ICONS.includes(item?.Icon as IconType)
                ? (item?.Icon as IconType)
                : 'gardahold'
            }
            color={color || 'gray200'}
            size="24"
          />
        ) : (
          item?.Icon && (
            <Figure
              type={
                ALL_FIGURES.includes(item?.Icon as FigureType)
                  ? (item?.Icon as FigureType)
                  : 'verkfaeri'
              }
              color={color || 'gray200'}
              fill={color || undefined}
              size={iconSize || '24'}
            />
          )
        )}
        <Box
          width="full"
          flexGrow={1}
          px={2}
          pl={item.Icon ? 3 : 1}
          alignItems="center"
          justifyContent="space-between"
          display="flex"
        >
          <Text
            mb={0}
            variant="textIntro"
            color={
              itemActive && itemActive === item.Name ? 'primary300' : 'dark500'
            }
            fontWeight={
              itemActive && itemActive === item.Name ? 'bold' : 'medium'
            }
          >
            {item.Name}
          </Text>
          {item.Children && item.Children.length > 0 && (
            <Icon type="arrowRight" size="18" />
          )}
        </Box>
      </Box>
    </MenuExpandableAnchor>
  </Box>
)
