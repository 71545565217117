import * as colors from './colors'
import * as fonts from './fonts'
import { Palette } from './palettes'

const anchorFocusStyles = (palette: Palette) => ({
  ':focus': {
    outline: `${palette.primary400} solid 2px`,
  },
  ':focus:not(:focus-visible)': {
    outline: 'none',
  },
  ':focus-visible': {
    outline: `${palette.primary400} solid 2px`,
  },
})

const anchors = (palette: Palette) => ({
  standard: {
    color: palette.primary300,
    fontWeight: fonts.fontWeights.medium,
    ':after': {
      content: '',
      position: 'absolute',
      bottom: '-6px',
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: palette.primary300,
      opacity: 0,
      transition: 'opacity 200ms',
    },
    ':hover': {
      color: palette.primaryHover,
      ':after': {
        opacity: 1,
      },
    },
    ...anchorFocusStyles(palette),
  },
  'standard-regular': {
    color: palette.primary300,
    fontWeight: fonts.fontWeights.regular,
    ':after': {
      content: '',
      position: 'absolute',
      bottom: '-6px',
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: palette.primary300,
      opacity: 0,
      transition: 'opacity 200ms',
    },
    ':hover': {
      color: palette.primaryHover,
      ':after': {
        opacity: 1,
      },
    },
    ...anchorFocusStyles(palette),
  },
  white: {
    color: colors.white,
    fontWeight: fonts.fontWeights.medium,
    ':after': {
      content: '',
      position: 'absolute',
      bottom: '-6px',
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: colors.white,
      opacity: 0,
      transition: 'opacity 200ms',
    },
    ':hover': {
      color: palette.primaryHover,
      ':after': {
        opacity: 1,
      },
    },
    ...anchorFocusStyles(palette),
  },
  dark: {
    color: colors.dark500,
    fontWeight: fonts.fontWeights.regular,
    ':after': {
      content: '',
      position: 'absolute',
      bottom: '-6px',
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: colors.dark500,
      opacity: 0,
      transition: 'opacity 200ms',
    },
    ':hover': {
      color: palette.primaryHover,
      ':after': {
        opacity: 1,
      },
    },
  },
})

export const anchorSizes = {
  standard: {
    fontSize: [15],
  },
  small: {
    fontSize: [12, 12, 14],
  },
  medium: {
    fontSize: [15, 15, 18],
  },
}

export const getAnchorTheme = (palette: Palette) => ({
  anchors: anchors(palette),
  anchorSizes: anchorSizes,
})
