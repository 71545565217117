import { FC } from 'react'
import { FadeInContent } from 'components/menu/menu.styles'
import { SearchResults } from 'types'
import { Anchor, Box, Text } from 'ui/core'

export const SearchQueryResults: FC<{
  searchLink: string
  resultsName: string
  res: SearchResults[]
}> = ({ searchLink, resultsName, res }) =>
  (res && res.length > 0 && (
    <FadeInContent>
      <Box
        py={2}
        width="full"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Anchor size="medium" fontWeight="black" href={searchLink}>
          {resultsName}
        </Anchor>
        <a href={searchLink}>
          <Box borderRadius="large" bg="primary300">
            <Text
              px={1}
              m={0}
              variant="textSmall"
              color="white"
              fontWeight="regular"
            >
              {res.length}
            </Text>
          </Box>
        </a>
      </Box>
      {res.slice(0, 5).map((res, idx) => (
        <SearchResultRow
          key={`search-categories-res-${idx}`}
          first={idx < 1}
          {...res}
        />
      ))}
    </FadeInContent>
  )) ||
  null

export const SeeMoreRow: FC<{ totalRes: number; href: string }> = ({
  totalRes,
  href,
}) =>
  (totalRes > 5 && (
    <Box
      pt={4}
      pb={2}
      display="flex"
      justifyContent="center"
      width="full"
      height="full"
    >
      <Anchor fontWeight="light" href={href}>
        Sjá allar niðurstöður ({totalRes})
      </Anchor>
    </Box>
  )) ||
  null

const SearchResultRow: FC<SearchResults & { first?: boolean }> = ({
  url,
  name,
  first,
}) => (
  <Box py={1} borderTop={first ? 'none' : 'light'}>
    <Anchor variant="dark" fontWeight="light" size="small" href={url}>
      {name}
    </Anchor>
  </Box>
)
