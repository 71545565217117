import { FC, useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { SearchResultsResponse } from 'types'
import { Box, Button, Hidden, Text } from 'ui/core'
import { DropdownWrapper } from '../headerSearchInput.styles'
import { SearchQueryResults, SeeMoreRow } from '../searchResults/searchResults'
import { SearchResultStatus } from 'helpers/hooks/useSearch'

export const SearchDropdown: FC<{
  query: string
  searchStatus: SearchResultStatus
  results: SearchResultsResponse | null
  closeSearch: () => void
  total: number
}> = ({ query, searchStatus, results, total, closeSearch }) => {
  const [height, setHeight] = useState<string | number>(0)
  const ref = useRef(null)
  const searchLink = '/leit?q=' + query

  useEffect(() => {
    if (searchStatus === 'passive' && height !== 0) setHeight(0)
    else if (
      (searchStatus === 'loading' || searchStatus === 'fulfilled') &&
      height === 0
    )
      setHeight('auto')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStatus])

  return (
    <Hidden below="md" width="full" position="relative">
      <Box
        ref={ref}
        height="full"
        width="full"
        position={['fixed', 'fixed', 'absolute']}
        right={[0, 0, 0, 'inherit']}
        zIndex="header"
      >
        <AnimateHeight duration={400} height={height}>
          <DropdownWrapper py={4} px={6} bg="white">
            {searchStatus === 'fulfilled' && total < 1 ? (
              <Box>
                <Text variant="h5">
                  Engar efnissíður fundust fyrir þessa leit
                </Text>
                <a href={searchLink}>
                  <Button size="medium">Leita í vörulista</Button>
                </a>
              </Box>
            ) : (
              <>
                {results?.categories && results.categories.length > 0 && (
                  <SearchQueryResults
                    res={results?.categories || []}
                    resultsName="Vöruflokkar"
                    searchLink={searchLink}
                  />
                )}
                <Box pt={4} pb={2}>
                  {results?.pages && results.pages.length > 0 && (
                    <SearchQueryResults
                      res={results?.pages || []}
                      resultsName="Efnissíður"
                      searchLink={searchLink}
                    />
                  )}
                </Box>
              </>
            )}
            <SeeMoreRow totalRes={total} href={searchLink} />
          </DropdownWrapper>
        </AnimateHeight>
      </Box>
    </Hidden>
  )
}
