import { AllPallettes } from './palettes'

export const transparent = 'transparent'
export const inherit = 'inherit'
export const white = '#ffffff'
export const black = '#000000'
export const background = '#EFEFEF'
export const backgroundDark = '#E4E0E0'
export const gray100 = '#F9F9F9'
export const gray200 = '#A1A1A1'
export const dark100 = '#EBEBEB'
export const dark200 = '#7A7A7A'
export const dark300 = '#5D5D5D'
export const dark400 = '#292929' // also headline color
export const dark500 = '#050505' // headline hover
export const husa = AllPallettes.husa.primary300
export const husaDarker = AllPallettes.husa.primary400
export const bloma = AllPallettes.bloma.primary300
export const blomaDarker = AllPallettes.bloma.primary400
export const iskraft = AllPallettes.iskraft.primary300
export const iskraftDarker = AllPallettes.iskraft.primary400
