import axios from 'axios'
import { useState } from 'react'
import { SearchResultsResponse } from 'types'

const SearchRouteBaseUrl = '/Umbraco/surface/search/Search'

export type SearchResultStatus = 'loading' | 'fulfilled' | 'error' | 'passive'

export const useSearch = () => {
  const [results, setResults] = useState<SearchResultsResponse | null>(null)
  const [status, setStatus] = useState<SearchResultStatus>('passive')

  const search = async (input: string) => {
    setStatus('loading')
    if (input === '') {
      setResults(null)
      setStatus('fulfilled')
    }
    const res = await axios.get<SearchResultsResponse>(
      `${SearchRouteBaseUrl}?searchTerm=${input}&site=`,
    )
    if (res && res.data) {
      const formattedPages =
        (res.data.pages &&
          res.data.pages.filter((r) => r.name !== 'Checkout')) ||
        res.data.pages
      const result = { categories: res.data.categories, pages: formattedPages }
      setResults(result)
      setStatus('fulfilled')
    } else {
      setResults(null)
      setStatus('error')
    }
  }

  const clear = async () => {
    setStatus('passive')
    setResults(null)
  }

  const getTotal = () =>
    (results &&
      (results?.pages?.length || 0) + (results?.categories?.length || 0)) ||
    0

  return {
    search,
    clear,
    results,
    status,
    getTotal,
  }
}
