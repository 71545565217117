import styled from 'styled-components'
import { Box } from 'ui/core'

export const DropdownWrapper = styled(Box)`
  min-width: 400px;
`

export const Dimmer = styled(Box)<{ isSearching: boolean }>`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: ${({ isSearching }) => (isSearching ? 0.4 : 0)};
  transition: all 0.3s ease-in-out;
  pointer-events: none;
`
