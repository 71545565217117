import { FC } from 'react'
import { Anchor, Box, Button, Figure, Icon, Text } from 'ui/core'
import { SearchIconWrapper } from './mobileSearch.styles'
import { SearchResultsResponse } from 'types'
import {
  SearchQueryResults,
  SeeMoreRow,
} from '../../searchResults/searchResults'
import { SearchInput } from 'components/searchInput/searchInput'

interface Props {
  query: string
  isSearching: boolean
  setQuery: (q: string) => void
  loading: boolean
  results: SearchResultsResponse | null
  total: number
  closeModal: () => void
  searchLink: string
}
export const MobileSearch: FC<Props> = ({
  closeModal,
  results,
  isSearching,
  loading,
  query,
  total,
  searchLink,
  setQuery,
}) => (
  <Box minHeight="viewportHeight" position="relative" py={6}>
    <Box width="full" display="flex" justifyContent="flex-end" py={2}>
      <Anchor onClick={closeModal}>
        <Box display="flex" alignItems="center" py={3}>
          <Icon type="close" color="dark300" size="18" />
          <Text m={0} py={0} px={1} color="dark300" variant="textIntro">
            Loka
          </Text>
        </Box>
      </Anchor>
    </Box>
    <Box
      position="relative"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Box flexGrow={1} display="flex" alignItems="flex-end">
        <SearchInput
          boxSize="large"
          name="search-input-mobile"
          type="search"
          autoComplete="off"
          value={query}
          placeholder="Sláðu inn leitarorð"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.location.href = searchLink
            }
          }}
          onChange={(e) => setQuery(e.target.value)}
        />
      </Box>
      <SearchIconWrapper>
        <Figure type="search" color="primary300" size={[20, 25, 30, 40]} />
      </SearchIconWrapper>
    </Box>
    <Box p={2} bg="white">
      {isSearching && query !== '' && query.length < 3 ? (
        <Box width="full" justifyContent="center" textAlign="center">
          <Text variant="h5" color="dark300">
            {results?.message || 'Leitin er of stutt'}
          </Text>
        </Box>
      ) : !loading && isSearching && total < 1 && query !== '' ? (
        <Box width="full" justifyContent="center" textAlign="center">
          <Box>
            <Text variant="h5" color="dark300">
              Engar efnissíður fundust fyrir þessa leit
            </Text>
            <a href={searchLink}>
              <Button size="medium">Leita í vörulista</Button>
            </a>
          </Box>
        </Box>
      ) : (
        <>
          {results?.categories && results.categories.length > 0 && (
            <SearchQueryResults
              res={results?.categories || []}
              resultsName="Vöruflokkar"
              searchLink={searchLink}
            />
          )}
          <Box
            pt={results?.categories && results.categories.length > 0 ? 2 : 0}
            pb={2}
          >
            {results?.pages && results.pages.length > 0 && (
              <SearchQueryResults
                res={results?.pages || []}
                resultsName="Efnissíður"
                searchLink={searchLink}
              />
            )}
          </Box>
        </>
      )}
      <SeeMoreRow totalRes={total} href={searchLink} />
    </Box>
  </Box>
)
