import { FC, ReactElement } from 'react'
import { SearchResultsResponse } from 'types'
import { Box, Container } from 'ui/core'
import { ModalBase } from 'ui/core/modalBase/modalBase'
import { zIndices } from 'ui/theme'
import { MobileSearch } from '../mobileSearch/mobileSearch'

interface Props {
  baseId: string
  disclosure?: ReactElement
  ariaLabel?: string
  isVisible?: boolean
  loading: boolean
  isSearching: boolean
  setIsSearching: (isSearching: boolean) => void
  query: string
  setQuery: (q: string) => void
  results: SearchResultsResponse | null
  total: number
  searchLink: string
}

export const Modal: FC<Props> = ({
  baseId,
  disclosure,
  setIsSearching,
  isVisible,
  ...props
}) => {
  return (
    <ModalBase
      baseId={baseId}
      disclosure={disclosure}
      zIndex={zIndices.header - 2}
      onVisibilityChange={(isVisible) => setIsSearching(isVisible)}
      backdropBg="rgba(26, 61, 95, 0.4)"
      position="centered"
    >
      {({ closeModal }: { closeModal: () => void }) => (
        <Box
          position="relative"
          width="viewportWidth"
          height="viewportHeight"
          overflowY="scroll"
          bg="white"
        >
          <Container>
            <MobileSearch closeModal={closeModal} {...props} />
          </Container>
        </Box>
      )}
    </ModalBase>
  )
}
