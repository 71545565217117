import React, { FC, ReactElement, useEffect } from 'react'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import { StyledBackdrop, StyledDialog, Position } from './modalBase.styles'

interface Props {
  baseId: string
  /**
   * Optional disclosure element
   */
  disclosure?: ReactElement
  /**
   * Default visibility state
   */
  visible?: boolean
  /**
   * Optional cb function that is fired when the modal visibility changes
   */
  onVisibilityChange?: (isVisible: boolean) => void
  backdropBg?: string
  position?: Position
  dialogAnimation?: 'fade-in' | 'slide-left' | 'none'
  ariaLabel?: string
  zIndex?: number
}

export const ModalBase: FC<Props> = ({
  baseId,
  visible,
  disclosure,
  position,
  onVisibilityChange,
  backdropBg,
  dialogAnimation = 'fade-in',
  ariaLabel,
  zIndex,
  children,
}) => {
  const modal = useDialogState({
    animated: true,
    baseId,
    visible,
  })

  useEffect(() => {
    onVisibilityChange && onVisibilityChange(modal.visible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.visible, onVisibilityChange])

  const closeModal = () => modal.hide()

  return (
    <>
      {disclosure ? (
        <DialogDisclosure {...modal}>
          {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
        </DialogDisclosure>
      ) : null}
      <StyledBackdrop
        {...modal}
        zIndex={zIndex}
        bg={backdropBg}
        position={position || 'centered'}
      >
        <StyledDialog
          {...modal}
          animation={dialogAnimation}
          aria-label={ariaLabel}
        >
          {typeof children === 'function' ? children({ closeModal }) : children}
        </StyledDialog>
      </StyledBackdrop>
    </>
  )
}
