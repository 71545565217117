import { FC } from 'react'
import { SearchResultsResponse } from 'types'
import { Icon } from 'ui/core'
import { Modal } from './modal/modal'
import { StyledAnchor } from './searchModal.styles'

interface Props {
  loading: boolean
  isSearching: boolean
  setIsSearching: (isSearching: boolean) => void
  query: string
  setQuery: (q: string) => void
  results: SearchResultsResponse | null
  total: number
}

export const SearchModal: FC<Props> = ({ isSearching, query, ...props }) => (
  <Modal
    baseId="search-modal"
    ariaLabel="Leitargluggi"
    disclosure={
      <StyledAnchor aria-label="Opna leit">
        <Icon type="search" size="26" color="primary300" />
      </StyledAnchor>
    }
    query={query}
    isSearching={isSearching}
    searchLink={'/leit?q=' + query}
    {...props}
  />
)
