import { MenuItemHoverAnchor } from 'components/menu/menu.styles'
import React, { FC } from 'react'
import { ThemeVariant } from 'types'
import { Box, Hidden, Text, Figure, Icon, IconType } from 'ui/core'
import { FigureType } from 'ui/core/figure/figure'

export interface FooterColumnProps {
  title: string
  links: {
    text: string
    url?: string
    target?: string
    icon?: IconType
    email?: boolean
  }[]
  figure?: FigureType
  phone?: string
  theme?: ThemeVariant
  noFigureSpace?: boolean
}

export const FooterColumn: FC<FooterColumnProps> = ({
  title,
  links,
  figure,
  phone,
  noFigureSpace,
}) => (
  <Box
    width={['full', 'full', 'full', figure ? '3/12' : '3/10']}
    px={4}
    pt={[4, 4, 4, 0]}
  >
    {figure && (
      <Box height="2/10">
        <Figure type={figure} size={150} />
      </Box>
    )}
    <Text fontWeight="bold" variant="h5" color="black">
      {title}
    </Text>
    {links.map(({ text, url, target, icon }, idx) => (
      <MenuItemHoverAnchor
        size="small"
        variant="standard-regular"
        href={url || ''}
        target={target || '_self'}
        key={idx}
      >
        <Box display="flex" alignItems="center">
          {icon && (
            <Box pb={1} pr={1}>
              <Icon type={icon} color="primary300" size="18" />
            </Box>
          )}
          <Text pl={figure && !noFigureSpace ? 1 : 0} color="dark400">
            {text}
          </Text>
        </Box>
      </MenuItemHoverAnchor>
    ))}
    {phone && (
      <>
        <Hidden below="lg">
          <Text variant="h3" fontWeight="extraBold" color="primary300">
            {phone}
          </Text>
        </Hidden>
        <Hidden above="lg">
          <Text variant="h2" fontWeight="extraBold" color="primary300">
            {phone}
          </Text>
        </Hidden>
      </>
    )}
  </Box>
)
