import styled, { css, keyframes } from 'styled-components'
import { Anchor, Box } from 'ui/core'

export const StyledMenu = styled(Box)``

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const LogoAnchor = styled.a`
  display: block;
`
export const MenuExpandableAnchor = styled(Anchor)`
  width: 100%;
  > * {
    width: 100%;
    flex-grow: 1;
  }
  transition: all 150ms ease-in;
  &:hover {
    p {
      color: ${({ theme }) => theme.colors.primary300};
      transition: all 150ms ease-in;
    }
  }
`
interface MenuItemWrapperProps {
  active?: boolean
  noHover?: boolean
}
export const MenuItemWrapper = styled(Box)<MenuItemWrapperProps>`
  cursor: pointer;
  background: ${({ active, theme }) =>
    active ? theme.colors.dark100 : theme.colors.white};
  transition: all 150ms ease-in;
  ${({ noHover }) =>
    !noHover &&
    css`
      &:hover {
        background: ${({ theme }) => theme.colors.gray100};
        transition: all 150ms ease-in;
      }
    `};
`

export const FadeInContent = styled(Box)`
  opacity: 0;
  animation: ${appear} 300ms linear forwards;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    animation-delay: 120ms;
  }
`

interface ScollIndicatorProps {
  visible?: boolean
}
export const ScrollBg = styled(Box)`
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.colors.white} 50%, ${theme.colors.transparent})`};
`
export const ScollIndicator = styled(Box)<ScollIndicatorProps>`
  opacity: 1;
  pointer-events: none;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 300ms ease-in;
`

export const MenuItemHoverAnchor = styled(Anchor)`
  > *,
  svg,
  p {
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    > *,
    svg,
    p {
      color: ${({ theme }) => theme.colors.primary300};
      transition: all 0.3s ease-in-out;
    }
  }
`
export const MenuHoverAnchor = styled(Anchor)`
  > *,
  svg,
  p {
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    > *,
    svg,
    p {
      color: ${({ theme }) => theme.colors.black};
      transition: all 0.3s ease-in-out;
    }
  }
`
