import { FC } from 'react'
import { Footer } from 'types'
import { Box } from 'ui/core'
import { FooterColumn } from './footerColumn'
import { GeneralInformationColumn, SocialMediaColumn } from './values'

interface Props {
  footer: Footer
}

export const FooterComponent: FC<Props> = ({ footer }) => (
  <Box
    width="full"
    bg="white"
    display="flex"
    justifyContent="center"
    mt={[8, 8, 10, 12, 16]}
  >
    <Box
      display="flex"
      flexDirection={['column', 'column', 'column', 'row']}
      justifyContent="space-around"
      alignItems="stretch"
      width={['full', 'full', 'full', 'full', '8/10']}
      height="full"
      pt={[4, 6, 8]}
      pb={[4, 8, 12]}
    >
      <FooterColumn
        noFigureSpace
        theme={footer.theme}
        {...GeneralInformationColumn[footer.theme]}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={['column', 'column', 'column', 'row']}
        height="full"
        width="9/12"
      >
        {footer.columns.map(({ Title, Links }, idx) => (
          <FooterColumn
            key={idx}
            title={Title}
            links={Links.map((l) => ({
              text: l.Title,
              url: l.Url,
              target: l.Target,
            }))}
          />
        ))}
        <FooterColumn {...SocialMediaColumn} />
      </Box>
    </Box>
  </Box>
)
