import React, { createElement, FC, forwardRef } from 'react'
import { ThemeInterface, ThemeResponsiveProp } from 'ui/theme'
import styled from 'styled-components'
import { StyledBox, StyledBoxProps } from '../box/box.styles'
import { ReactComponent as LogoHusa } from './svgs/logo-husa.svg'
import { ReactComponent as LogoHusaNoBg } from './svgs/logo-husa-no-bg.svg'
import { ReactComponent as LogoHusaText } from './svgs/logo-husa-text.svg'
import { ReactComponent as LogoBloma } from './svgs/logo-bloma.svg'
import { ReactComponent as LogoBlomaText } from './svgs/logo-bloma-text.svg'
import { ReactComponent as Search } from './svgs/search-icon.svg'
import { ReactComponent as Arstidavorur } from './svgs/arstidavorur.svg'
import { ReactComponent as Byggingarefni } from './svgs/byggingarefni.svg'
import { ReactComponent as Fatnadur } from './svgs/fatnadur.svg'
import { ReactComponent as Gluggaroghurdir } from './svgs/gluggaroghurdir.svg'
import { ReactComponent as Golfefni } from './svgs/golfefni.svg'
import { ReactComponent as Rekstravorur } from './svgs/heimilisogrekstravorir.svg'
import { ReactComponent as Heimilistaeki } from './svgs/heimilistaeki.svg'
import { ReactComponent as Ofn } from './svgs/heimilistaeki.svg'
import { ReactComponent as Ljos } from './svgs/ljosograflagnaefni.svg'
import { ReactComponent as Malning } from './svgs/malning.svg'
import { ReactComponent as Verkfaeri } from './svgs/verkfaeri.svg'
import { ReactComponent as LogoIskraft } from './svgs/iskraftLogo.svg'
import { ReactComponent as LogoIskraftBg } from './svgs/iskraftLogoBg.svg'

export type FigureMapper<T extends string> = Record<T, React.FC>

type StyledFigureWrapperProps = StyledBoxProps & {
  fill?: string
}

const StyledFigureWrapper = styled(StyledBox)<StyledFigureWrapperProps>`
  svg {
    width: 1em;

    path {
      stroke: ${({ color, theme }) =>
        color ? theme.colors[color as keyof ThemeInterface['colors']] : null};
      fill: ${({ fill, theme }) =>
        fill ? theme.colors[fill as keyof ThemeInterface['colors']] : null};
    }
  }
`
export const ALL_FIGURES = [
  'logoHusa',
  'logoHusaNoBg',
  'logoHusaText',
  'logoBloma',
  'logoBlomaText',
  'logoIskraft',
  'logoIskraftBg',
  'search',
  'arstidarvorur',
  'byggingarefni',
  'fatnadur',
  'gluggaroghurdir',
  'golfefni',
  'heimilisogrekstravorir',
  'heimilistaeki',
  'ofn',
  'ljosograflagnaefni',
  'malning',
  'verkfaeri',
] as const
export type FigureType = typeof ALL_FIGURES[number]
const figureMapper: FigureMapper<FigureType> = {
  logoHusa: LogoHusa as unknown as React.FC,
  logoHusaNoBg: LogoHusaNoBg as unknown as React.FC,
  logoHusaText: LogoHusaText as unknown as React.FC,
  logoBloma: LogoBloma as unknown as React.FC,
  logoBlomaText: LogoBlomaText as unknown as React.FC,
  logoIskraft: LogoIskraft as unknown as React.FC,
  logoIskraftBg: LogoIskraftBg as unknown as React.FC,
  search: Search as unknown as React.FC,
  arstidarvorur: Arstidavorur as unknown as React.FC,
  byggingarefni: Byggingarefni as unknown as React.FC,
  fatnadur: Fatnadur as unknown as React.FC,
  gluggaroghurdir: Gluggaroghurdir as unknown as React.FC,
  golfefni: Golfefni as unknown as React.FC,
  heimilisogrekstravorir: Rekstravorur as unknown as React.FC,
  heimilistaeki: Heimilistaeki as unknown as React.FC,
  ofn: Ofn as unknown as React.FC,
  ljosograflagnaefni: Ljos as unknown as React.FC,
  malning: Malning as unknown as React.FC,
  verkfaeri: Verkfaeri as unknown as React.FC,
}

export interface FigureBaseProps {
  type: FigureType
  color?: string
  size?: ThemeResponsiveProp<string | number>
  fill?: string
}

export const Figure: FC<FigureBaseProps> = forwardRef<
  HTMLDivElement,
  FigureBaseProps
>(({ type, color, size, children, fill, ...props }, ref) => {
  const figure = figureMapper[type]

  return (
    <StyledFigureWrapper
      as="span"
      display="inline-flex"
      height="100%"
      alignItems="center"
      color={color}
      fontSize={size}
      fill={fill}
      ref={ref}
    >
      {createElement(figure, props, children)}
    </StyledFigureWrapper>
  )
})
