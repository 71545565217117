import { FC } from 'react'
import { ThemeVariant } from 'types'
import { Box, Figure, FigureType, Hidden } from 'ui/core'
import { ThemeColorProp } from 'ui/theme'
import { StyledLogoAnchor } from './headerMainLinks.styles'

interface Props {
  theme: ThemeVariant
}

// Only have one link if project is Ískraft
// Otherwise have húsa and blóma both available, order by which
// website user is currently on
export const HeaderMainLinks = ({ theme }: Props) => {
  if (theme === ThemeVariant.ISKRAFT) {
    return (
      <Box display="flex" height="full">
        <HeaderMainLink
          isIskraft={true}
          isCurrentTheme={true}
          icon="logoIskraft"
          iconText="logoIskraft"
          color="iskraft"
          colorDark="iskraftDarker"
        />
      </Box>
    )
  }
  return (
    <Box display="flex" height="full">
      <HeaderMainLink
        isCurrentTheme={theme === ThemeVariant.HUSA}
        icon="logoHusa"
        iconText="logoHusaText"
        color="husa"
        colorDark="husaDarker"
      />
      <StyledLogoAnchor
        href="/blomaval"
        color="bloma"
        hoverColor="blomaDarker"
        order={theme === ThemeVariant.BLOMA ? 1 : 2}
      >
        <HeaderMainLink
          isCurrentTheme={theme === ThemeVariant.BLOMA}
          href="/blomaval"
          icon="logoBloma"
          iconText="logoBlomaText"
          color="bloma"
          colorDark="blomaDarker"
        />
      </StyledLogoAnchor>
    </Box>
  )
}

interface HeaderMainLinkProps {
  isCurrentTheme: boolean
  icon: FigureType
  iconText: FigureType
  color: ThemeColorProp
  colorDark: ThemeColorProp
  isIskraft?: boolean
  href?: string
}

// Adapt header main links to appropriate website
// If user is on husa, show husa as larger with text
// then vice versa with Blómaval
export const HeaderMainLink: FC<HeaderMainLinkProps> = ({
  icon,
  iconText,
  color,
  colorDark,
  isCurrentTheme,
  href,
  isIskraft,
}) => (
  <Box display="flex" height="full">
    <StyledLogoAnchor
      href={href || '/'}
      color={color}
      hoverColor={colorDark}
      order={!isCurrentTheme ? 2 : 1}
    >
      <Box
        display="flex"
        alignItems="center"
        py={[3, 3, 2]}
        px={isIskraft ? [3, 4, 5] : isCurrentTheme ? [1, 1, 3] : [1, 1, 2]}
        height="full"
      >
        <Figure
          type={icon}
          fill={icon !== 'logoHusa' ? 'white' : undefined}
          size={isIskraft ? [75, 80, 130] : [32, 38]}
        />
        {isCurrentTheme && !isIskraft && (
          <Hidden below="md">
            <Box pl={[0, 1, 2]}>
              <Figure type={iconText} fill="white" size={[0, 0, 120]} />
            </Box>
          </Hidden>
        )}
      </Box>
    </StyledLogoAnchor>
  </Box>
)
