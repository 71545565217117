import { FC, useEffect, useState } from 'react'
import { MenuNode } from 'types'
import { Box, Text } from 'ui/core'
import {
  FadeInContent,
  MenuHoverAnchor,
  MenuItemHoverAnchor,
} from './menu.styles'

interface Props {
  menu: MenuNode
  hasScrolled: boolean
  partitionSize?: number | null
}

export const MenuItem: FC<Props> = ({
  menu,
  hasScrolled,
  partitionSize = null,
}) => {
  const [partitionedMenu, setPartitionedMenu] = useState<MenuNode[][]>([])
  // const [showScroll, setShowScroll] = useState<boolean>(false)

  // Partitions menu in matrix of n-th sized arrays
  const partitionMenu = (input: MenuNode[], n: number) => {
    var output = []
    for (var i = 0; i < input.length; i += n) {
      output[output.length] = input.slice(i, i + n)
    }
    return output
  }
  // Formats menu - partitions it in segments
  // based on menu size
  const formatMenu = () => {
    if (!menu || !menu.Children) return
    let length = partitionSize ? partitionSize : 2
    if (!partitionSize) {
      if (menu.Children.length < 5) length = 1
      else if (menu.Children.length > 10) length = 3
      else if (menu.Children.length > 15) length = 4
    }
    // setShowScroll(length > 2)
    setPartitionedMenu(partitionMenu(menu.Children, length))
  }
  useEffect(() => {
    formatMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    formatMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return (
    <FadeInContent
      width="full"
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      height="viewportHeight"
    >
      {menu && (
        <Box
          display="flex"
          width="full"
          justifyContent="stretch"
          alignItems="flex-start"
          px={2}
          py={1}
        >
          {partitionedMenu &&
            partitionedMenu.length > 0 &&
            partitionedMenu.map((segment, idx) => (
              <Box
                key={idx}
                width={['full', 'full', '3/12']}
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                alignItems="flex-start"
              >
                {segment.map(({ Name, Url, Children }, idx) => (
                  <Box
                    key={idx}
                    width="full"
                    display="flex"
                    flexDirection="column"
                  >
                    <MenuHoverAnchor href={Url}>
                      <Text
                        variant="textSmall"
                        color="primary300"
                        fontWeight="bold"
                        pb={2}
                        pl={1}
                      >
                        {Name}
                      </Text>
                    </MenuHoverAnchor>
                    <Box pb={2} pl={1}>
                      {Children &&
                        Children.map(({ Name, Url }, idx) => (
                          <MenuItemHoverAnchor href={Url} key={idx}>
                            <Text
                              color="dark500"
                              variant="textSmall"
                              fontWeight="regular"
                            >
                              {Name}
                            </Text>
                          </MenuItemHoverAnchor>
                        ))}
                      {!Children ||
                        (Children.length < 1 && (
                          <MenuItemHoverAnchor href={Url}>
                            <Text
                              color="dark500"
                              variant="textSmall"
                              fontWeight="regular"
                            >
                              Sjá vörur
                            </Text>
                          </MenuItemHoverAnchor>
                        ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            ))}
        </Box>
      )}
      {/* No scroll indicator for Ískraft unless requested later */}
      {/* {showScroll && (
        <ScollIndicator
          position="absolute"
          visible={hasScrolled}
          top={0}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          height="viewportHeight"
          width="full"
        >
          <ScrollBg height="4/10">
            <Icon type="chevronDown" size={'60'} />
          </Box>
        </ScollIndicator>
      )} */}
    </FadeInContent>
  )
}
