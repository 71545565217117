import { Palette } from './palettes'
import * as colors from './colors'

const borders = (palette: Palette) => ({
  standard: `1px solid ${colors.dark400}`,
  light: `1px solid ${colors.dark100}`,
  transparent: '1px solid transparent',
  dark: `1px solid ${colors.dark300}`,
  darkGray: `1px solid ${colors.backgroundDark}`,
  focus: `2px solid ${palette.primary400}`,
  none: 'none',
})

export const radii = {
  small: 4,
  large: 8,
  xlarge: 24,
  circle: '100%',
  oval: '60px',
}

const shadows = {
  standard: '0px 0px 32px rgba(0, 0, 0, 0.05)',
  hover: '0px 0px 32px rgba(0, 0, 0, 0.1)',
  menu: '0px 16px 32px rgba(0, 0, 0, 0.05);',
}

export const getBorderTheme = (palette: Palette) => ({
  radii,
  shadows,
  borders: borders(palette),
})
