import { Dialog, DialogBackdrop } from 'reakit/Dialog'
import styled, { css } from 'styled-components'

export type Position = 'centered' | 'static'

interface StyledBackdropProps {
  bg?: string
  zIndex?: number
  position: Position
}

const backdropPositionCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledBackdrop = styled(DialogBackdrop)<StyledBackdropProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: ${({ bg }) => bg || 'rgba(255, 255, 255, 0.3)'};
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  z-index: ${({ zIndex }) => zIndex || 1000};

  ${({ position }) => position === 'centered' && backdropPositionCenter}

  &[data-enter] {
    opacity: 1;
  }
`

interface StyledDialogProps {
  animation: 'fade-in' | 'slide-left' | 'none'
}

const dialogFadeIn = css`
  opacity: 0;
  transition: opacity 250ms ease-in-out;

  &[data-enter] {
    opacity: 1;
  }
`

const dialogSlideLeft = css`
  transform: translateX(105%);
  transition: transform 800ms ease-in;

  &[data-enter] {
    transition: transform 400ms ease-out;
    transform: translateX(0%);
  }
`

export const StyledDialog = styled(Dialog)<StyledDialogProps>`
  outline: none;

  ${({ animation }) => animation === 'fade-in' && dialogFadeIn}
  ${({ animation }) => animation === 'slide-left' && dialogSlideLeft}
`
