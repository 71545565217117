import { FC } from 'react'
import {
  FadeInContent,
  MenuHoverAnchor,
  MenuItemHoverAnchor,
} from 'components/menu/menu.styles'
import { MenuNode } from 'types'
import { Box, GridColumn, GridRow, Text } from 'ui/core'

interface Props {
  menu: MenuNode
  hasScrolled: boolean
}

export const ContentMenuItem: FC<Props> = ({ menu, hasScrolled }) => {
  // Partitions menu in matrix of n-th sized arrays
  const partitionMenu = (input: MenuNode[], n: number) => {
    var output = []
    for (var i = 0; i < input.length; i += n) {
      output[output.length] = input.slice(i, i + n)
    }
    return output
  }
  const menus = !menu || !menu.Children ? [] : partitionMenu(menu.Children, 6)
  return (
    <FadeInContent
      width="full"
      height="viewportHeight"
      px={[2, 2, 2, 2, 4]}
      py={6}
    >
      {menu &&
        menus &&
        menus.length > 0 &&
        menus.map((segment, idx) => (
          <GridRow key={'content-menu-row-' + idx}>
            {segment.map(({ Name, Url, Children }, idx) => (
              <GridColumn key={idx} width={['full', 'full', '2/12']} pushBottom>
                <Box
                  key={idx}
                  width="full"
                  display="flex"
                  flexDirection="column"
                >
                  <Box pl={1}>
                    <MenuHoverAnchor href={Url}>
                      <Text
                        variant="textSmall"
                        color="primary300"
                        fontWeight="bold"
                        pb={1}
                      >
                        {Name}
                      </Text>
                    </MenuHoverAnchor>
                    {Children &&
                      Children.map(({ Name, Url }, idx) => (
                        <MenuItemHoverAnchor href={Url} key={idx}>
                          <Text
                            color="dark500"
                            variant="textSmall"
                            fontWeight="regular"
                          >
                            {Name}
                          </Text>
                        </MenuItemHoverAnchor>
                      ))}
                  </Box>
                </Box>
              </GridColumn>
            ))}
          </GridRow>
        ))}
      {/* No scroll indicator for Ískraft unless requested later */}
      {/* <ScollIndicator
        position="absolute"
        visible={hasScrolled}
        top={0}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        height="viewportHeight"
        width="full"
      >
        <ScrollBg
          height="5/10"
          width="full"
          display="flex"
          justifyContent="center"
        >
          <Icon type="chevronDown" size={'60'} />
        </ScrollBg>
      </ScollIndicator> */}
    </FadeInContent>
  )
}
