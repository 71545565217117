import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { HeaderApp, FooterApp } from 'apps'

const renderHeader = () => {
  const entry = document.getElementById('header-app-entry')
  if (entry) {
    ReactDOM.render(
      <React.StrictMode>
        <HeaderApp />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderFooter = () => {
  const entry = document.getElementById('react-footer-entry')
  if (entry) {
    ReactDOM.render(
      <React.StrictMode>
        <FooterApp />
      </React.StrictMode>,
      entry,
    )
  }
}

// Inject all components to the dom tree
const renderComponents = () => {
  renderHeader()
  renderFooter()
}

renderComponents()
reportWebVitals()
