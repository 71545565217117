import { SearchInput } from 'components/searchInput/searchInput'
import { useSearch } from 'helpers/hooks'
import { FC, useEffect, useRef, useState } from 'react'
import { Box, Hidden } from 'ui/core'
import { HeaderButton } from '../headerControls/headerControls'
import { Dimmer } from './headerSearchInput.styles'
import { SearchDropdown } from './searchDropdown/searchDropdown'
import { SearchModal } from './searchModal/searchModal'

export const HeaderSearchInput: FC<{ closeAllMenus: () => void }> = ({
  closeAllMenus,
}) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const [query, setQuery] = useState('')
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)
  const { search, results, status, clear, getTotal } = useSearch()
  useEffect(() => {
    if (mobileSearchOpen) closeAllMenus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileSearchOpen])
  useEffect(() => {
    if (query.length >= 3) search(query)
    else if (results !== null) clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  const setInputFocus = () => {
    if (ref && ref.current) {
      ref.current.focus()
    }
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent={['flex-start', 'flex-end', 'space-between']}
        alignItems="center"
        height="full"
        position="relative"
      >
        <Dimmer
          width="viewportWidth"
          height="viewportHeight"
          position="fixed"
          bg="dark400"
          isSearching={status === 'fulfilled' || status === 'loading'}
          zIndex="-1"
          display={['none', 'none', 'inherit']}
        />
        <Hidden below="md" height="full" width="full">
          <SearchInput
            ref={ref}
            autoComplete="off"
            placeholder="Leita..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                window.location.href = '/leit?q=' + query
              }
            }}
          />
          <Box
            height="full"
            onClick={() => setInputFocus()}
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={[1, 2, 4]}
            borderRight="light"
          >
            <HeaderButton
              iconLeft="search"
              iconColor="primary300"
              hiddenBelow="md"
            />
          </Box>
        </Hidden>
        <Hidden above="md" px={[1, 2]}>
          <SearchModal
            query={query}
            loading={status === 'loading'}
            isSearching={mobileSearchOpen}
            setIsSearching={setMobileSearchOpen}
            setQuery={setQuery}
            results={results}
            total={getTotal()}
          />
        </Hidden>
      </Box>
      <Hidden below="md" width="full" position="relative">
        <SearchDropdown
          closeSearch={() => !mobileSearchOpen && setQuery('')}
          query={query}
          searchStatus={status}
          results={results}
          total={getTotal()}
        />
      </Hidden>
    </>
  )
}
