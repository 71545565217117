import {
  ThemeColorProp,
  ThemeFontSizeProp,
  ThemeResponsiveProp,
} from 'ui/theme'
import { createElement, FC } from 'react'
import { IconType as ReactIconType } from 'react-icons/lib'
import { StyledBox } from '../box/box.styles'
import {
  FiClock,
  FiHeart,
  FiShoppingCart,
  FiSearch,
  FiInfo,
  FiUser,
  FiArrowLeft,
  FiArrowRight,
  FiChevronDown,
  FiGift,
  FiX,
  FiCheck,
} from 'react-icons/fi'
import {
  IoMdFlower,
  IoMdPaw,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
} from 'react-icons/io'
import {
  RiPlantFill,
  RiSeedlingFill,
  RiToolsFill,
  RiBookFill,
} from 'react-icons/ri'
import { GiFlowerPot, GiBarbecue, GiSofa, GiFruitTree } from 'react-icons/gi'

export type IconMapper<T extends string> = Record<T, ReactIconType>

export const ALL_ICONS = [
  'time',
  'info',
  'user',
  'heart',
  'cart',
  'search',
  'close',
  'arrowLeft',
  'arrowRight',
  'chevronDown',
  'afskorinblom',
  'ferming',
  'plontur',
  'pottarogker',
  'fraeoglaukar',
  'gardhusgogn',
  'gardurinn',
  'gardahold',
  'gjafavara-1',
  'ofn',
  'gaeludyravorur',
  'checkmark',
  'facebook',
  'instagram',
  'youtube',
] as const
export type IconType = typeof ALL_ICONS[number]

const iconMapper: IconMapper<IconType> = {
  time: FiClock,
  info: FiInfo,
  user: FiUser,
  heart: FiHeart,
  cart: FiShoppingCart,
  search: FiSearch,
  arrowLeft: FiArrowLeft,
  arrowRight: FiArrowRight,
  chevronDown: FiChevronDown,
  close: FiX,
  afskorinblom: IoMdFlower,
  ferming: RiBookFill,
  plontur: GiFlowerPot,
  pottarogker: RiPlantFill,
  fraeoglaukar: RiSeedlingFill,
  gardhusgogn: GiSofa,
  gardurinn: GiFruitTree,
  gardahold: RiToolsFill,
  'gjafavara-1': FiGift,
  ofn: GiBarbecue,
  gaeludyravorur: IoMdPaw,
  checkmark: FiCheck,
  facebook: IoLogoFacebook,
  instagram: IoLogoInstagram,
  youtube: IoLogoYoutube,
}

export type IconProps = {
  type: IconType
  color?: ThemeColorProp
  size?: ThemeResponsiveProp<ThemeFontSizeProp>
}

export const Icon: FC<IconProps> = ({ type, color, size, ...props }) => {
  const icon = iconMapper[type]

  return (
    <StyledBox
      as="span"
      display="inline-flex"
      height="100%"
      alignItems="center"
      color={color || 'icon'}
      fontSize={size}
    >
      {createElement(icon, props)}
    </StyledBox>
  )
}
