import { FC, useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { StyledMenu } from '../menu/menu.styles'
import { ThemeVariant } from 'types'
import { Box, Hidden } from 'ui/core'
import { PromoImage } from './imagePromo'
import { MobileContentMenu } from './mobileContentMenu'
import { MenuNode } from 'types/megamenu'
import { ContentMenuItem } from './contentMenuItem/contentMenuItem'

interface Props {
  nav: MenuNode
  open: boolean
  theme: ThemeVariant
  promoImage: PromoImage
  goBack: () => void
}

export const ContentMenu: FC<Props> = ({ open, nav, theme, goBack }) => {
  const [showScroll, setShowScroll] = useState<boolean>(true)
  const [height, setHeight] = useState<string | number>(0)

  useEffect(() => {
    if (!open && height !== 0) setHeight(0)
    else if (open && height === 0) setHeight('auto')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <StyledMenu
      position="absolute"
      top={0}
      left={0}
      zIndex="-1"
      width="full"
      bg="white"
      boxShadow="menu"
      autoFocus={open ? true : false}
    >
      <AnimateHeight duration={400} height={height}>
        <Hidden below="lg" autoFocus={open ? true : false} width="full">
          <Box
            pt={12}
            pb={6}
            width="full"
            autoFocus={open ? true : false}
            height="viewportHeight"
            overflow="scroll"
            onScroll={(event) => {
              if (event.currentTarget.scrollTop >= 60) {
                setShowScroll(false)
              } else if (
                event.currentTarget.scrollTop < 60 &&
                showScroll === false
              ) {
                setShowScroll(true)
              }
            }}
          >
            <ContentMenuItem
              menu={nav}
              key={nav.Name}
              hasScrolled={theme === ThemeVariant.BLOMA ? false : showScroll}
            />
            {/*
              They didn't want the promo image :'( :'(
              For now I'll comment it out in hope they
              see the light and want this back in
              */}
            {/* <ImagePromoBox {...promoImage} /> */}
          </Box>
        </Hidden>
        <Hidden above="lg">
          <MobileContentMenu menu={nav} theme={theme} goBack={goBack} />
        </Hidden>
      </AnimateHeight>
    </StyledMenu>
  )
}
